import React, { useEffect, useState } from 'react'
import Confirmation from "../Confirmation/Confirmation"
import './PopUpRequest.css'
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };
  

const PopUpRequest = ({organization,onClose,refresh, acceptNotify, rejectNotify}) => {
    const [ accountDetail, setAccountDetail ] = useState({})
    const [ members, setMembers ] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await axios.get(
                `${apiUrl}/api/bank-detail/get-bank-detail?organizationId=${organization?._id}`,
                {
                  headers: {
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hbmlzaEBnbWFpbC5jb20iLCJjb250YWN0IjoiNzY2NjA4OTkxMiIsIm5hbWUiOiJNYW5pc2giLCJpc0FkbWluIjpmYWxzZSwia2V5IjoiQUl6YVN5Qi10NWxITkRINHp6U2JOaWtPRGJOQ0F2aVFLMFhRdGxFIiwiaWF0IjoxNzA1NTUxMzI1fQ.MIQDj-Uq22kyZSv6eGmKAI9nw_5_ZTdC6kZmhstBVL4',
                    'Content-Type': 'application/json',
                  },
                }
              );
      
              setAccountDetail(response.data.data);
            } catch (error) {
              console.log("error",error);
            }
          };
      
          fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await axios.get(
                `${apiUrl}/api/member/get-members?organizationId=${organization?._id}`,
                {
                  headers: {
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hbmlzaEBnbWFpbC5jb20iLCJjb250YWN0IjoiNzY2NjA4OTkxMiIsIm5hbWUiOiJNYW5pc2giLCJpc0FkbWluIjpmYWxzZSwia2V5IjoiQUl6YVN5Qi10NWxITkRINHp6U2JOaWtPRGJOQ0F2aVFLMFhRdGxFIiwiaWF0IjoxNzA1NTUxMzI1fQ.MIQDj-Uq22kyZSv6eGmKAI9nw_5_ZTdC6kZmhstBVL4',
                    'Content-Type': 'application/json',
                  },
                }
              );
      
              setMembers(response.data.data);
            } catch (error) {
              console.log("error",error);
            }
          };
      
          fetchData();
    }, [])
    

    const cancelPopUp = ()=>{
        onClose()
    }
        console.log(members)
  return (<>
    <div className='pop'>
        <div className='pop-container'>
            <img src="assest/cancel_icon.svg" className='cancel' onClick={cancelPopUp}/>
            <div className='pop-up-header'>
                Basic Detail
            </div>
            <div className='field-container'>
                <div className='sub-field-container'>
                    <p className='title'>Organization name</p>
                    <p className='value'>{organization.organizationName}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>Registration Date</p>
                    <p className='value'>{formatDate(organization.registrationDate)}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>Registration Number</p>
                    <p className='value'>{organization.registrationNumber}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>Email</p>
                    <p className='value'>{organization.email}</p>
                </div>
            </div>

            <div className='field-container'>
                <div className='sub-field-container'>
                    <p className='title'>Website</p>
                    <p className='value'>{organization.website}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>Trustee Contact (Trustee 1)</p>
                    <p className='value'>{organization.trusteeContactNumber1}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>Trustee Contact (Trustee 2)</p>
                    <p className='value'>{organization.trusteeContactNumber2}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>Address</p>
                    <p className='value'>{organization.address}</p>
                </div>
            </div>

            <div className='field-container'>
                <div className='sub-field-container'>
                    <p className='title'>State</p>
                    <p className='value'>{organization.state}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>Pincode</p>
                    <p className='value'>{organization.pincode}</p>
                </div>
            </div>

            <div className='pop-up-header'>
                Members Detail
            </div>

            <div className='field-container overflow' >
                
               {members.length > 0 ? members.map((item,index)=>( <div className='sub-field-container'>
                    <div className='image-container' key={index} >
                        <img src="assest/test_user.png" className='member-profile'/>
                        <div className='sub-field-container'>
                            <p className='value mar-rev'>{item.name}</p>
                            <p className='title'>{item.name} ({item.upiId})</p>
                        </div>
                    </div>
                        </div>)):<div className='image-container'>N/A</div>}
                    </div>

            <div className='pop-up-header'>
                A/c Detail
            </div>


            <div className='field-container'>
                <div className='sub-field-container'>
                    <p className='title'>Registered Name</p>
                    <p className='value'>{accountDetail?accountDetail.registeredName: "N/A"}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>A/c Number</p>
                    <p className='value'>{accountDetail?accountDetail.accNumber: "N/A"}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>IFSC Code</p>
                    <p className='value'>{accountDetail?accountDetail.ifscCode: "N/A"}</p>
                </div>
                <div className='sub-field-container'>
                    <p className='title'>Branch</p>
                    <p className='value'>{accountDetail?accountDetail.branch: "N/A"}</p>
                </div>
            </div>
        </div>
        <Confirmation id={organization._id} onClose={onClose} refresh={refresh} acceptNotify={acceptNotify} rejectNotify={rejectNotify}/>
    </div>
    
  </>
  )
}

export default PopUpRequest