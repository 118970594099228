import React, { useState } from 'react'
import './Tabs.css'

const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="tabs-container">
          <div className="tabs">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab ${index === activeTab ? 'active' : ''}`}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>
          <div className="tab-contents">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab-content ${index === activeTab ? 'active' : ''}`}
              >
                {tab.content}
              </div>
            ))}
          </div>
        </div>
      );
}

export default Tabs