// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from "./Pages/Login/Login";
import Asidebar from "./Components/Asidebar/Asidebar";
import NavBar from "./Components/NavBar/NavBar";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Support from "./Pages/Support/Support";
import Logout from './Pages/Logout/Logout';

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);

  const loginIn = (newState)=>{
    setLoggedIn(true)
  }

  const logOut = (newState)=>{
    setLoggedIn(false)
  }
  
  return (
    <>
     <Router>
      {isLoggedIn && (
          <>
            <Asidebar />
            {/* <NavBar /> */}
          </>
        )}
       <Routes>
         <Route path="/" element={<Login onLogin={loginIn} />} />
         <Route path="/dashboard" element={<Dashboard/>} />
         <Route path="/support" element={<Support/>} />
         <Route path="/logout" element={<Logout onLogOut={logOut}/>} />
         <Route path="*" element={<h1>Page Not Found</h1>} />
       </Routes>
      </Router>
    </>
  )
}

export default App

