import React, { useEffect, useState } from 'react'
import Ticket from '../../Components/Ticket/Ticket'
import './Support.css'
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const Support = () => {
  const [tickets,setTickets] = useState([])
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/ticket/get-ticket`, {
          headers: {
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWQ2ZjNkMTIzZDQzMWViNmU1YjdiODMiLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsImlzQWRtaW4iOnRydWUsInBhc3N3b3JkIjoiJDJiJDEwJFBaaDRJOE1KLi8wVk4vUXEzSUozWnVFampYb01zOEtBNUdkeTBvS1E3a2RRTEp1TmZxSWh5IiwiaWF0IjoxNzA4NTg2MjAwfQ.Kylt-8k8S_R4QMF-cydfc9I9MwY_ocPmUioT6ov03Lc'
          },
        });

        setTickets(response.data.data)
      } catch (error) {
        console.log(error)
      }
    };

    fetchData();
  }, [refresh]); 
  
  const refreshTickets = () => {
    setRefresh(!refresh); // Toggle refresh state
};
      
  return (
    <div className='support-page'>
      <div className='support-title'>
         Support Tickets
      </div>
     {tickets.length > 0 && tickets.map(ticket => (
        <Ticket
          key={ticket._id}
          id={ticket._id}
          userId={ticket.userId}
          ticketId={ticket.ticketId}
          name={ticket.name}
          contact={ticket.contact}
          description={ticket.description}
          status={ticket.status}
          timestamp={ticket.timestamp}
          refreshTickets={refreshTickets}
        />
      ))}
    </div>
  )
}

export default Support