import React, { useEffect, useState } from 'react'
import Card from '../../Components/Card/Card'
import Table from '../../Components/Table/Table';
import Tabs from '../../Components/Tabs/Tabs';
import './Dashboard.css'
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [organizations, setOrganizations] = useState([]);
  const [ refresh, setRefresh ] = useState(false)
  const [totalActiveUser, setTotalActiveUser] = useState(0)
  const [downloads, setDownloads] = useState(0)
  const [organization, setOrganization] = useState(0)
  const [totalRaised, setTotalRaised] = useState(0)
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/organization/get-all-organizations`, {
            headers: {
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWQ2ZjNkMTIzZDQzMWViNmU1YjdiODMiLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsImlzQWRtaW4iOnRydWUsInBhc3N3b3JkIjoiJDJiJDEwJFBaaDRJOE1KLi8wVk4vUXEzSUozWnVFampYb01zOEtBNUdkeTBvS1E3a2RRTEp1TmZxSWh5IiwiaWF0IjoxNzA4NTg2MjAwfQ.Kylt-8k8S_R4QMF-cydfc9I9MwY_ocPmUioT6ov03Lc'
            },
          });
          setOrganizations(response.data.data);
          setTotalActiveUser(response.data.usersWithActiveOrganizationCount)
          setDownloads(response.data.totalUsersCount)
          setOrganization(response.data.totalOrganizationsCount)
          setTotalRaised(response.data.totalRaised)
        } catch (error) {
          console.log(error)
        }
      };
  
      fetchData();
    }, [refresh]); 

    const sortedOrganizations = [...organizations].sort((a, b) => {
      
      const dateA = new Date(a.registrationDate);
      const dateB = new Date(b.registrationDate);
    
      return dateB - dateA;
    });

    const activeOrganizations = organizations.filter(org => org.isApproved === "active");
    const inActiveOrganizations = organizations.filter(org => org.isApproved === "inActive");
    const rejectedOrganizations = organizations.filter(org => org.isApproved === "reject");

  const tabs =  [
    {
      label: 'New Reviews',
      content: (
        <Table OrganizationList={sortedOrganizations} refresh={()=>setRefresh(!refresh)}/>
      ),
    },
    {
      label: 'Active',
      content: (
         <Table OrganizationList={activeOrganizations} refresh={()=>setRefresh(!refresh)}/>
      ),
    },
    {
      label: 'Inactive',
      content: (
        <Table OrganizationList={inActiveOrganizations} refresh={()=>setRefresh(!refresh)}/>
      ),
    },
    {
      label: 'Rejected',
      content: (
        <Table OrganizationList={rejectedOrganizations} refresh={()=>setRefresh(!refresh)}/>
      ),
    },
  ];
  return (<>
            <div className='dashboard-page'>
              <div className='dashboard-title'>
                Summary
              </div>
              <div className='dashboard-container'>
                  <Card icon="total_active_user" header="Total Active Users" count={totalActiveUser}/>
                  <hr className='dashboard-line'/>
                  <Card icon="download" header="Downloads" count={downloads}/>
                  <hr className='dashboard-line'/>
                  <Card icon="organization" header="Organizations" count={organization}/>
                  <hr className='dashboard-line'/>
                  <Card icon="total_raised" header="Total Raised" count={totalRaised}/>
              </div>
              <div className='dashboard-title'>
                Organization
              </div>
              <div className='dashboard-container'>
                <Tabs tabs={tabs}/>
              </div>
            </div>
          </>
  )
}

export default Dashboard