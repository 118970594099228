import React from 'react'
import axios from 'axios'
import './Confirmation.css'
const apiUrl = process.env.REACT_APP_API_URL;

const Confirmation = ({id, onClose, refresh, acceptNotify, rejectNotify}) => {
  const accept = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/organization/update-status?id=${id}`,
        {
          isApproved: "active",
          reason: ""
        },
        {
          headers: {
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hbmlzaEBnbWFpbC5jb20iLCJjb250YWN0IjoiNzY2NjA4OTkxMiIsIm5hbWUiOiJNYW5pc2giLCJpc0FkbWluIjpmYWxzZSwia2V5IjoiQUl6YVN5Qi10NWxITkRINHp6U2JOaWtPRGJOQ0F2aVFLMFhRdGxFIiwiaWF0IjoxNzA1NTUxMzI1fQ.MIQDj-Uq22kyZSv6eGmKAI9nw_5_ZTdC6kZmhstBVL4',
            'Content-Type': 'application/json'
          }
        }
      );
      onClose()
      acceptNotify()
      refresh(refresh)
    } catch (error) {
      console.error(error);
    }
  };

  const reject = async () => {
    onClose()
    rejectNotify(id)
  };
  return (
    <div className='confirmation-container'>
        <div className='sub-container' onClick={accept}>
            <img src="assest/correct_icon.svg" className='accept-icon'/>
            <p className='accept'>Accept</p>
        </div>
        <hr className='rejection-line'/>
        <div className='sub-container' onClick={reject}>
            <img src="assest/wrong_icon.svg"  className='reject-icon'/>
            <p className='reject'>Reject</p>
        </div>
    </div>
  )
}

export default Confirmation