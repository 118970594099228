import React, { useState } from 'react'
import './Ticket.css'
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const Ticket = ({id,userId,ticketId,name,contact,description,status,timestamp,refreshTickets}) => {

    const [refresh,setRefresh] = useState(false)

    const closeTicket=async()=>{
            try {
              const response = await axios.post(`${apiUrl}/api/ticket/close-ticket?id=${id}`, {
                headers: {
                  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWQ2ZjNkMTIzZDQzMWViNmU1YjdiODMiLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsImlzQWRtaW4iOnRydWUsInBhc3N3b3JkIjoiJDJiJDEwJFBaaDRJOE1KLi8wVk4vUXEzSUozWnVFampYb01zOEtBNUdkeTBvS1E3a2RRTEp1TmZxSWh5IiwiaWF0IjoxNzA4NTg2MjAwfQ.Kylt-8k8S_R4QMF-cydfc9I9MwY_ocPmUioT6ov03Lc'
                },
              });
               refreshTickets();
            } catch (error) {
              console.log(error)
            }
    }
  return (
    <div className='ticket-container'>
        <div className='ticket-details'>
            <p className='ticket-number'>Ticket ID: {ticketId}</p>
            <p className='ticket-date'>{timestamp}</p>
        </div>
        <div className='ticket-of'>
            {name} ({contact})
        </div>
        <div className='ticket-issue'>
            {description}
        </div>
        <div className='ticket-action'>
           {!status ? <button className='ticket-open'>
                Open
            </button>:
            <button className='ticket-close'>
                Close
            </button>}
           {!status && <button className='ticket-close-button' onClick={closeTicket}>
                Mark Close
            </button>}
        </div>
       
    </div>
  )
}

export default Ticket