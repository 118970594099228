import React, { useState } from 'react'
import PopUpAccepted from '../PopUpAccepted/PopUpAccepted';
import PopUpRequest from '../PopUpRequest/PopUpRequest';
import RejectionReason from '../RejectionReason/RejectionReason';
import './Table.css'

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  return date.toLocaleDateString('en-US', options);
};

const Table = ({OrganizationList,refresh}) => {
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [ accepted, setAccepted ] = useState(false)
  const [ rejected, setRejected ] = useState(false)
  const [ id, setId ] = useState('')

  const handleRowClick = (organization) => {
    setSelectedOrganization(organization);
  };


  return (
    <>
     <table border="1">
          <thead>
            <tr>
              <th>Organization Name</th>
              <th>Registration No.</th>
              <th>Since</th>
              <th>Contact</th>
              <th>Email</th>
              <th>Request Date</th>
              <th>Take Action</th>
            </tr>
          </thead>
          <tbody>

          {OrganizationList  && OrganizationList.map((organization, index) => (
            <tr key={index} className="organization" onClick={() => handleRowClick(organization)}>
              <td>
                <div className='table-profile'>
                  <img src="assest/org_test.png" className='profile-icon' alt="Profile Icon" />
                  <div className='profile-detail'>
                    <p className='profile-header'>{organization.organizationName}</p>
                    <p className='profile-user'>@{organization.username}</p>
                  </div>
                </div>
              </td>
              <td>{organization.registrationNumber}</td>
              <td>{formatDate(organization.registrationDate)}</td>
              <td>{organization.trusteeContactNumber1}</td>
              <td>{organization.email}</td>
              <td>{formatDate(organization.registrationDate)}</td>
              <td>
                <img src="assest/eye_open_icon.svg" className='action-icon' alt="Eye Open Icon" />
                <img src="assest/call_icon.svg" className='action-icon' alt="Call Icon" />
              </td>
            </tr>
          ))}

          </tbody>
        </table>

        {selectedOrganization && (
          <PopUpRequest
            organization={selectedOrganization}
            onClose={() => setSelectedOrganization(null)}
            acceptNotify={()=>setAccepted(true)}
            rejectNotify={(id)=>{setRejected(true);setId(id)}}
            refresh={refresh}
          />
        )}

        {accepted && (
          <PopUpAccepted 
            onClose={() => setAccepted(false)}  
          />
        )}

        {rejected && (
          <RejectionReason 
            id={id}
            onClose={() => setRejected(false)}  
            refresh={refresh}
          />
        )}

    </>
  )
}

export default Table